import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import PanoRoomUniqueVideo from "../assets/panoroom_unique_measurement.mp4";

const PanoRoomUnique = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pexels-the-coach-space-2977565.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout maxWidth={600}>
      <SEO 
      title="What does make PanoRoom unique among floor plan apps?"
      description="Learn how PanoRoom differs from other Augmented-Reality powered floor plan apps"
      />
      <div style={{ padding: "0px 1em" }}>
        <h1>What does make PanoRoom unique among floor plan apps?</h1>
        <p>
          There are tens of floor plan apps on the market to choose from. Just
          open the AppStore and type "floor plan" (go ahead, try it!). Likely,
          you will see an endless feed filled with look-alike app icons. When we
          were creating PanoRoom, we tried many of them in pursuit of creating a
          software which is faster, more precise, and more intuitive to use. So,
          no matter whether you are a realtor or a casual user, chances are
          PanoRoom will be a great fit for you.
        </p>

        <h2>
          Reason #1: We use a unique measurement mechanism, which dramatically
          increases precision.
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "1em",
          }}
        >
          <video
            controls={false}
            muted
            autoPlay
            playsInline
            loop
            style={{ maxWidth: "100%" }}
          >
            <source src={PanoRoomUniqueVideo} type="video/mp4" />
          </video>
        </div>
        <p>
          Most of the floor plan apps currently allow you to use you device
          camera to take measurements of each of the rooms on the floor plan.
          This feature, while useful, is very often based on the corners of the
          room, which does not allow for capture of furnished apartments. There
          are some apps which try to overcome it, but are still missing several
          key conceps in the implementation.
        </p>

        <p>
          PanoRoom will discover all elements which are measurable, and will
          allow you to take measurements on walls, furniture, and let you place
          inch-accurate dimensions at baseboards. All by just aiming your device
          in the place where you want to place the wall. Our app will let you
          take precise measurements on all modern iOS devices, no matter whether
          you are using iPhone 7 or an iPhone Pro with LIDAR.
        </p>

        <h2>Reason #2: We are most likely the quickest app on the market.</h2>
        <p>
          Most of the apps will allow you to measure one room only, and will
          later prompt you to stich them together to make a full floor plan.
          While this sounds rather innocent at start, it actually has some
          sirious implications. Imagine creating a large apartment with 8 rooms:
          it means a lot of time wasted on just starting the actual scan and
          stitching the results each time (given that one also has to adjust the
          scan results manually).
        </p>

        <p>
          At PanoRoom, we made a significant improvement over that. We add all
          rooms on the go, and evens the placed door automatically transfer from
          one room to another! It also means a better accuracy: manually
          stitched rooms rarely keep the correct dimensions. We don't ask you to
          fix any inaccuracies: the full floor plan is available right after the
          scan.
        </p>

        <p>
          (There are some apps which allow you to shoot a video, and someone
          watches it to manually re-create the interior. We however didn't
          include them here, as we wanted to keep the comparision within the
          apps which allow you take the plans instantly.)
        </p>

        <h2>
          Reason #3: We are using Artificial Intelligence to help you take the
          best measurement.
        </h2>

        <p>
          You heard that right. We were very determined to make the app as fast
          and easy to use as possible, so we employed an accurate AI solution to
          help you making a floor plan just by looking around. Even when your
          iPhone or iPad does not have a LIDAR scanner, it will recognize walls
          and other surfaces to allow you for a robust dimension placement.
        </p>
        <p>
          By doing so, we kill two birds with one stone: allow you for a faster
          apartment capture, which is also more accurate. By marking AI-detected
          walls, rather than corners, we once again increase the precision of
          the measurements.
        </p>

        <h2>Reason #4: We are there for you.</h2>
        <p>
          We are a dynamic startup which takes great care in providing you with
          the best customer experience available. We often tutor our clients
          individually and stay in touch, in case they have any suggestions on
          how to improve our app. Just click the "contact" tab and see it for
          yourself!
        </p>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.image.childImageSharp}
        />
        <h2>Final words</h2>
        <p>
          Among many advantages PanoRoom has over its competitors, our accuracy
          over a wide range of devices is hard to beat. We allow for ultra-fast,
          accurate capture of entire apartments, and we are excited to offer you
          this ability; which fits right into your pocket📱.
        </p>

        {/* <SEO title="Why most of the floor plan making apps suck, and what can be done about that?" />
    <h1>Why most of the floor plan making apps suck, and what can be done about that?</h1>
<p>
If you happen to be working as a realtor (whether as an agent, or a photographer), it is very likely
that you have already used some kind of software to create a plan of a house which is about to be sold.
There is a plenthora of available solutions (just try googling!), each with its own set of advantages and disadvantages.
</p>
<p>
In this post I am going to concentrate on a small subset of them: floor plan apps which run on mobile devices.
The reason is that all other software requires the user to both take measurements in the field, and store it either as a list or
a sketch. This makes many computer-based floor plan creators very cumbersome to use, as an intermediate step is required.
However, many people are still using them, due to powerful features that are hard to be replicated in the mobile world.
</p>


<h2>Reason #1: Since 2017, we don't need tape measure anymore</h2>
If you casually browse App Store for plan creating apps, you will be instantly able to locate the apps which allow you to create plans.
So far, so good: say you want to quickly create a sketch of an apartment you have just arrived in, so you download the app



<h2>Reason #2: Most of the app don't take corner cases into account (literally)</h2>


<h3> Reason # </h3> */}
      </div>
    </Layout>
  );
};

export default PanoRoomUnique;
